
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap');

.pacific-rotate {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
  font-weight: 500 !important;
  line-height: 1.05;
  transform: rotate(-5deg);

  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.semi-big-text {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 2rem;
}
.txt-center {
  text-align: center;
}
